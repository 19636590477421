import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kazik Kluz | Full-Stack Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Kazik Kluz',
  subtitle: 'I am a Full-Stack Software Engineer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'meProfile.jpg',
  paragraphOne:
    'I am a web developer with industry knowledge of building websites and web applications. ',
  paragraphTwo:
    'I specialize in JavaScript and have experience working with React, NextJS, TailwindCSS. I also have experience working with Back-End technologies like NodeJS, MongoDB, PostgreSQL and AWS Cloud Services.',
  paragraphThree: 'Take a look at my work or get in touch! ',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'travelAdvisorSmall.png',
    title: 'Travel Advisor',
    info: 'An application that dynamically displays nearby restaurants, hotels and attractions based on geolocation or user-specified place on Earth. ',
    info2: 'Technologies used: React, Material UI, Rapid API, Google Maps API, Google Places API',
    url: 'https://zippy-cannoli-3dc892.netlify.app/',
    repo: 'https://github.com/KazikKluz/travel-advisor', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'crwnSmall.png',
    title: 'CRWN Clothing',
    info: 'An e-commerce web application for selling clothes online.',
    info2: 'Technologies used: React, Firebase, Redux with Sagas, Stripe',
    url: 'https://ubiquitous-parfait-225eeb.netlify.app/',
    repo: 'https://github.com/KazikKluz/crwn-workspace', // if no repo, the button will not show up
  },

  {
    id: nanoid(),
    img: 'devfinderSmall.png',
    title: 'DevFinder',
    info: 'Small application for searching developers on GitHub.',
    info2:
      'Technologies used: Next.js, TypeScript, Next Themes, Sass, Flexbox, GitHub REST API, Octokit, Jest, Testing Library, MSW.',
    url: 'https://kazikkluz.github.io/github-user-search/',
    repo: 'https://github.com/KazikKluz/github-user-search', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'payapi.png',
    title: 'PayAPI',
    info: 'Multi-page, responsive product website. ',
    info2: 'Technologies used: Next.js - React framework, TypeScript, TailwindCSS, Flexbox',
    url: 'https://kazikkluz.github.io/payapi-website/',
    repo: 'https://github.com/KazikKluz/payapi-website', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'minimalist.png',
    title: 'Minimalist Portfolio',
    info: 'Multi-page, responsive portfolio website. ',
    info2: 'Technologies used: Next.js - React framework, TypeScript, TailwindCSS, Flexbox',
    url: 'https://kazikkluz.github.io/minimalist-portfolio/',
    repo: 'https://github.com/KazikKluz/minimalist-portfolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'kazik.kluz@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Kazik_Kluz',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kazikkluz/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/KazikKluz',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
